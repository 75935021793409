import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, injectIntl } from 'react-intl';

import CurrencyConverter from '../../CurrencyConverter';
import messages from '../../../locale/messages';

import s from './ViewReservation.css';

class HostServiceFee extends Component {
    static propTypes = {
        hostId: PropTypes.string.isRequired,
        checkIn: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        hostPayout: PropTypes.shape({
            id: PropTypes.number.isRequired,
            payEmail: PropTypes.string.isRequired
        }),
        hostTransaction: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
        loading: PropTypes.bool,
        reservationId: PropTypes.number,
        reservationState: PropTypes.string.isRequired,
        completed: PropTypes.bool,
        openReservationModal: PropTypes.any.isRequired,
        cancelData: PropTypes.shape({
            payoutToHost: PropTypes.number.isRequired,
            currency: PropTypes.string.isRequired,
        }),
    };

    static defaultProps = {
        hostPayout: null,
        loading: false,
        completed: false,
        reservationId: 0,
    };

    render() {
        const { checkIn, loading, reservationId, reservationState, completed, cancelData, hostServiceFee } = this.props;
        const { id, amount, currency, hostPayout, hostTransaction } = this.props;

        let amountPaytoHost = 0, additionalStatus;
        let nextDay = moment(checkIn).add(1, 'days'), today = moment();
        let dayDifference = nextDay.diff(today, 'days'), status = { hostServiceFee }, action = false;

        if (hostPayout === null || hostPayout.payEmail === undefined) {
            additionalStatus = <FormattedMessage {...messages.noPayoutMethod} />
        }

        if ((dayDifference < 0 && hostPayout != null) || (reservationState === 'cancelled' && amountPaytoHost > 0)) {
            status = { hostServiceFee }
            action = true;
        } else if (dayDifference < 0) {
            status = 0;
        }

        return (
            <span>
                <CurrencyConverter amount={hostServiceFee}
                    from={currency} />
                {additionalStatus &&
                    <span>{' '}({additionalStatus})</span>
                }
            </span>
        );
    }
}

export default injectIntl(withStyles(s)(HostServiceFee));
