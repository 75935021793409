import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Table, Tr, Td, Thead, Th } from 'reactable';

import messages from '../../../locale/messages';
import s from './ReportManagement.css';
class ReportManagementInformation extends React.Component {

    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            userId: PropTypes.number.isRequired,
            reporterId: PropTypes.string.isRequired,
            reporterType: PropTypes.string.isRequired,
        })),
        formatMessage: PropTypes.func.isRequired,
    };

    static defaultProps = {
        data: []
    };

    render() {
        const { reportUserManagement, formatMessage } = this.props;

        return (
            <div className="table-responsive">
                <Table className="table" noDataText={formatMessage(messages.noRecordFound)} sortable={true}>
                    <Thead>
                        <Th scope="col">{formatMessage(messages.idLabel)}</Th>
                        <Th scope="col">{formatMessage(messages.reporterName)}</Th>
                        <Th scope="col">{formatMessage(messages.reporterEmail)}</Th>
                        <Th scope="col">{formatMessage(messages.userNameLabel)}</Th>
                        <Th scope="col">{formatMessage(messages.userEmailLabel)}</Th>
                        <Th scope="col">{formatMessage(messages.reportType)}</Th>
                        <Th scope="col">{formatMessage(messages.transferDate)}</Th>
                    </Thead>
                    {reportUserManagement?.reportsData?.length > 0 &&
                        reportUserManagement?.reportsData?.map((value, index) => {
                            let date = moment(value?.createdAt).format('MM/DD/YYYY');
                            return (
                                <Tr key={index}>
                                    <Td data-label={formatMessage(messages.idLabel)} column={formatMessage(messages.idLabel)}>
                                        {value?.id}
                                    </Td>
                                    {
                                        value?.reporterData && value?.reporterData?.displayName &&
                                        <Td data-label={formatMessage(messages.reporterName)} column={formatMessage(messages.reporterName)}>
                                            {value.reporterData.displayName}
                                        </Td>

                                    }
                                    {
                                        !value?.reporterData &&
                                        <Td data-label={formatMessage(messages.reporterName)} column={formatMessage(messages.reporterName)}>
                                            <FormattedMessage {...messages.userDeletedLabel} />
                                        </Td>
                                    }
                                    {
                                        value?.reporterData && value?.reporterEmail?.email &&
                                        <Td data-label={formatMessage(messages.reporterEmail)} column={formatMessage(messages.reporterEmail)}>
                                            <a
                                                href={"/users/show/" + value.userProfileId.profileId}
                                                target="_blank"
                                            >
                                                {value.reporterEmail.email}
                                            </a>
                                        </Td>
                                    }
                                    {
                                        !value?.reporterData && <Td data-label={formatMessage(messages.reporterEmail)} column={formatMessage(messages.reporterEmail)}>
                                            <FormattedMessage {...messages.userDeletedLabel} />
                                        </Td>
                                    }
                                    {
                                        value?.userData && value?.userData?.displayName &&
                                        <Td data-label={formatMessage(messages.userNameLabel)} column={formatMessage(messages.userNameLabel)} data={value.userData.displayName} />
                                    }
                                    {
                                        !value?.userData && <Td data-label={formatMessage(messages.userNameLabel)} column={formatMessage(messages.userNameLabel)}>
                                            <FormattedMessage {...messages.userDeletedLabel} />
                                        </Td>
                                    }
                                    {
                                        value?.userData && value?.userEmail?.email &&
                                        <Td data-label={formatMessage(messages.userEmailLabel)} column={formatMessage(messages.userEmailLabel)}>
                                            <a
                                                href={"/users/show/" + value?.userData?.profileId}
                                                target="_blank"
                                            >
                                                {value.userEmail.email}
                                            </a>
                                        </Td>
                                    }
                                    {
                                        !value?.userData
                                        && <Td data-label={formatMessage(messages.userEmailLabel)} column={formatMessage(messages.userEmailLabel)}>
                                            <FormattedMessage {...messages.userDeletedLabel} />
                                        </Td>
                                    }
                                    {
                                        value?.reportType &&
                                        <Td
                                            data-label={formatMessage(messages.reportType)}
                                            column={formatMessage(messages.reportType)}
                                            data={value?.reportType}
                                        >
                                        </Td>
                                    }
                                    {
                                        value && <Td data-label={formatMessage(messages.transferDate)} column={formatMessage(messages.transferDate)}>
                                            {date}
                                        </Td>
                                    }
                                </Tr>
                            );
                        })
                    }
                </Table>
            </div>
        );
    }
}

export default ReportManagementInformation;