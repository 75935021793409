import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid, Row, Col } from "react-bootstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import s from "./Tariffs.css";
import Link from "../../Link/Link";
import getAllTariffs from "./getAllTariffs.graphql";
import { flowRight as compose } from "lodash";
import { graphql } from "react-apollo";

class TariffsBlock extends Component {
  static propTypes = {
    formatMessage: PropTypes.any,
    refer: PropTypes.string,
    siteName: PropTypes.string.isRequired,
  };

  render() {
    const { refer, siteName, data } = this.props;
    console.log("Props", data.getAllTariffs);
    return (
      <Grid className={cx(s.container, s.hostingsection)}>
        <div className={cx(s.mainhedding, "mainheddingRTLMobile")}>
          <h3>Tariffs</h3>
        </div>
        <Row>
          {data.getAllTariffs?.map((tariff) => (
            <Col xs={12} sm={12} md={4} lg={4}>
              <div className={cx(s.steps)}>
                <Link to="/become-a-host/room">
                  <div className={s.cost}>{tariff.name}</div>{" "}
                  <div className={s.desc}>
                    <h1 className={s.title}>{tariff.name}</h1>
                    <p>{tariff.description}</p>
                    <ul>
                      <li>guest_commision: {tariff?.guest_commision}</li>
                      <li>guest_is_percent: {tariff?.guest_is_percent}</li>
                      <li>host_commision: {tariff?.host_commision}</li>
                      <li>host_is_percent: {tariff?.host_is_percent}</li>
                      <li>is_only_commission: {tariff?.is_only_commission}</li>
                    </ul>
                  </div>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      </Grid>
    );
  }
}

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
});

const mapDispatch = {};

export default compose(
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(getAllTariffs, {
    options: {
      fetchPolicy: "network-only",
      ssr: false,
    },
  })
)(TariffsBlock);
