import React from "react";
import cx from 'classnames';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Loader from "../../Loader/Loader";
import defaultPic from '/public/adminIcons/defaultAdmin.svg';
import bt from '../../../components/commonStyle.css';
import DeleteIcon from '/public/adminIcons/dlt.png';

class CommonImageDisplay extends React.Component {

    render() {
        const { loader, image, loading, isDefaultPic, isDelete, deleteImage } = this.props;
        return (
            <Loader show={loader} type={"page"}>
                <div className={'positionRelative'}>
                    {
                        !loading && <div style={{ backgroundImage: `url(${!isDefaultPic ? image : defaultPic})` }} className={cx(bt.profileImageBg, 'darkModeProfileImageBg', { [bt.defaultImg]: isDefaultPic })}></div>
                    }
                    {
                        !loading && !isDefaultPic && isDelete && <a href="javascript:void(0);" onClick={() => deleteImage()}
                            className={cx(bt.trashIconNew, 'trashIconNewRTL')}>
                            <img src={DeleteIcon} alt='Delete' />
                        </a>
                    }
                </div>
            </Loader>
        )
    }
}

export default withStyles(bt)(CommonImageDisplay);