import {
  VERIFY_PAYOUT_START,
  VERIFY_PAYOUT_SUCCESS,
  VERIFY_PAYOUT_ERROR,
} from '../../constants';
import { processStripePayment } from '../../core/payment/stripe/processStripePayment';
import { setLoaderStart, setLoaderComplete } from '../loader/loader';
import showToaster from '../../helpers/showToaster';

export function verifyPayout(currentAccountId, userId) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: VERIFY_PAYOUT_START,
    });

    await dispatch(setLoaderStart('payoutVerify'));

    try {
      let userDetails = {
        currentAccountId,
        userId
      };

      const { status, errorMessage } = await processStripePayment(
        'verifyPayout',
        userDetails
      );


      if (status && status === 200) {
        await dispatch({
          type: VERIFY_PAYOUT_SUCCESS,
          payload: {
            status
          }
        });
      } else {
        showToaster({ messageId: 'commonError', toasterType: 'error', requestContent: errorMessage })

        dispatch({
          type: VERIFY_PAYOUT_ERROR,
          payload: {
            error: errorMessage
          }
        });


      }
    } catch (error) {
      dispatch({
        type: VERIFY_PAYOUT_ERROR,
        payload: {
          error
        }
      });

      await dispatch(setLoaderComplete('payoutVerify'));
      return false;
    }

    return true;
  };
}
